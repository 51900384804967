import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { Checkbox, SaveIcon, Button, Modal, PlayButtonIcon, Text } from '@ting/ting-ui-components'
import { secondToHumanReadableTime } from '@@ting/utils/time'
import cn from 'classnames'

import { IVideo } from '@@ting/models'
import { SaveToPlaylistModal } from '@@ting/molecules'
import { fileURL } from '@@ting/utils'
import { useGetUserInfoWhenNeed } from '@@ting/services/api/query-hooks'
import routeGenerator from '@@ting/helpers'
import Routes from '@@ting/enums/routes'

import './VideoThumbnail.scss'
import { ObsOutlinedIcon } from '@ting/ting-ui-components/dist/icons'

type Props = {
  video: IVideo
  link?: string
  showHover?: boolean
  className?: string
  checkbox?: boolean
  checked?: boolean
  onSelect?: (id: number) => void
  id?: number
  showDuration?: boolean
  showAddPlayList?: boolean
  size?: 'small' | 'normal'
}

export const VideoThumbnail: FC<Props> = React.memo(
  ({
    className,
    link,
    video,
    showHover = true,
    checkbox = false,
    onSelect,
    checked = false,
    id,
    showAddPlayList = true,
    showDuration = true,
    size = 'normal',
  }) => {
    const [isHovered, setIsHovered] = useState(false)
    const [showSaveModal, setShowSaveModal] = useState(false)
    const { data: user } = useGetUserInfoWhenNeed()

    const toggleSaveToPlaylistOpen = () => {
      setShowSaveModal(!showSaveModal)
    }
    const handleOpenSaveModal = (e: React.MouseEvent) => {
      e.preventDefault()
      setShowSaveModal(true)
    }
    const handleOnMouseEnter = () => {
      setIsHovered(true)
    }

    const handleOnMouseLeave = () => {
      setIsHovered(false)
    }

    const duration = showDuration && secondToHumanReadableTime(video.duration)
    return (
      <div
        className={cn('video-thumbnail', className, {
          'video-thumbnail-show-hover': showHover,
          'video-thumbnail-small': size === 'small',
        })}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <Link
          to={link || routeGenerator(Routes.Video.watchVideoUUID, { videoUUID: video?.uuid })}
          className='video-thumbnail__link'
        >
          {checkbox && (
            <Checkbox
              id={video.name}
              onClick={() => {
                if (onSelect) {
                  onSelect(id)
                }
              }}
              checked={checked}
              className='video-poster-checkbox'
              name={video.name}
            />
          )}
          <img
            className={cn('video-thumbnail', className, {
              'video-thumbnail-show-hover': showHover,
              'video-thumbnail-small': size === 'small',
            })}
            alt={video.name}
            src={fileURL(video.thumbnailPath)}
          />
          {isHovered && video.animatedThumbnailPath && !video.stream && (
            <img alt={video.name} className='video-animated-thumbnail' src={fileURL(video.animatedThumbnailPath)} />
          )}
          {user && showAddPlayList && (
            <div className='thumbnail-actions'>
              <Button className='btn-action' onClick={handleOpenSaveModal} theme='secondary' icon={<SaveIcon />} />
            </div>
          )}
          {showDuration && size !== 'small' && (
            <div className='duration'>
              <Text size='body-small'>{duration}</Text>
            </div>
          )}
          {video.isLive && size !== 'small' && (
            <div className='live-tag'>
              <ObsOutlinedIcon width={22} height={22} />
              <Text size='body-small' fontWeight='bold'>
                LIVE
              </Text>
            </div>
            // <div className='viewers-tag'>
            //   <ViewersBadge viewers={video.views} />
            // </div>
          )}
          {video.userHistory?.currentTime && (
            <div
              className='history-progress'
              style={{ width: `${(video.userHistory?.currentTime / video.duration) * 100}%` }}
            />
          )}
          {size === 'small' && (
            <div className='video-thumbnail-playing'>
              <PlayButtonIcon /> Playing
            </div>
          )}
        </Link>

        <Modal onClose={toggleSaveToPlaylistOpen} isOpen={showSaveModal} scrollable>
          <SaveToPlaylistModal onSave={toggleSaveToPlaylistOpen} videoId={video.id} />
        </Modal>
      </div>
    )
  }
)
